import React from "react";
import Slider from "rc-slider";

class Results extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            employeeContributionPercentage: this.props.result.employeeContributionPercentage,
            employerContributionPercentage: this.props.result.employerContributionPercentage,
            retirementAge: this.props.about.retirementAge,
            oneDayTargetIncome: this.props.income.oneDayTargetIncome,
            oneDaySalary: this.props.income.oneDaySalary,
            yearlySalary: +(this.props.income.oneDaySalary * 365.25).toFixed(2),
            yearlyTargetIncome: +(this.props.income.oneDayTargetIncome * 365.25).toFixed(2),
            noEffectSalary: +(this.props.income.oneDaySalary * 365.25).toFixed(2),
            expandedData: []
        }
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.employeeContributionPercentage === undefined && current_state.employeeContributionPercentage !== props.result.employeeContributionPercentage) {
            return {
                employeeContributionPercentage: +props.result.result.employeeContributionPercentage.toFixed(2)
            }
        }
        if (current_state.employerContributionPercentage === undefined && current_state.employerContributionPercentage !== props.result.employerContributionPercentage) {
            return {
                employerContributionPercentage: +props.result.employerContributionPercentage.toFixed(2)
            }
        }
        return null;
    }

    formatCurrency = (value) => {
        return new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP'
        }).format(value)
    }

    changeValue = (key, event) => {
        let value;
        if (typeof event === 'object') {
            value = +event.target.value;
        } else {
            value = +event;
        }
        this.setState({[key]: value});
        setTimeout(() => {
            this.props.onDataChange(this.state);
        })
    }

    onEmployeeGrossChange = (event) => {
        this.changeValue('employeeContributionPercentage', event);
    }

    onEmployerGrossChange = (event) => {
        this.changeValue('employerContributionPercentage', event);
    }

    onTargetIncomeChange = (event) => {
        this.changeValue('yearlyTargetIncome', event);
    }

    onRetirementAgeChange = (event) => {
        this.changeValue('retirementAge', event);
    }

    onSalaryChange = (event) => {
        this.changeValue('noEffectSalary', event);
    }

    toggleCollapse = (index) => {
        const expandedData = [...this.state.expandedData];
        expandedData[index] = !expandedData[index];
        this.setState({expandedData});
    }

    render() {
        return <div className={'results'}>
            <table width={"100%"}>
                <thead>
                <tr>
                    <th>Age</th>
                    <th className={'no-mobile'}>State Pension</th>
                    <th className={'no-mobile'}>Defined Benefit</th>
                    <th className={'no-mobile'}>Other Income</th>
                    <th>Pot Income</th>
                    <th>Estimated Income</th>
                    <th>Shortfall / Surplus</th>
                </tr>
                </thead>
                <tbody>
                {this.props.result.tableData.map((row, index) => {
                    return <tr className={`${this.state.expandedData[index] ? "" : "collapsed"}`}>
                        <td data-th={"Age"} onClick={() => this.toggleCollapse(index)}>{row.age}</td>
                        <td data-th={"State Pension"} className={'no-mobile'}>{this.formatCurrency(Math.round(row.statePension))}</td>
                        <td data-th={"Defined Benefit"} className={'no-mobile'}>{this.formatCurrency(Math.round(row.definedBenefit))}</td>
                        <td data-th={"Other Income"} className={'no-mobile'}>{this.formatCurrency(Math.round(row.otherIncome))}</td>
                        <td data-th={"Pot Income"}>{this.formatCurrency(Math.round(row.potIncome))}</td>
                        <td data-th={"Estimated Income"}>{this.formatCurrency(Math.round(row.estimatedIncome))}</td>
                        <td data-th={"Shortfall / Surplus"} className={row.shortFallSurPlus < 0 ? 'negative' : 'positive'}>{row.shortFallSurPlus < 0 ? '-' : '+'}{this.formatCurrency(Math.abs(Math.round(row.shortFallSurPlus)))}</td>
                    </tr>})}
                </tbody>
            </table>
            <p><strong>The table above shows your total estimated income and your shortfall/surplus depending on your
                target retirement income.</strong></p>
            <p>The income from your pension pots (pot income) is based on you buying a guaranteed income for life (an
                annuity) which will start paying from your selected retirement age.</p>
            <p>If you have input a retirement age that is lower than your state pension age, you may notice that the
                income from your pension pots is lower at state pension age. This is due to inflation reducing the
                buying power of the income each year.</p>
            <p>Move the sliders to see how you can affect your pension income.</p>
            <div className={'selector-row'}>
                <div className={"flex items-center justify-between mb-2"}>
                <div className={'section-heading'}>Your gross contributions</div>
                <div className={'slider-container'}>

                    <div className={'top flex items-center'}>
                        {this.formatCurrency(this.props.result.employeeContribution)} per year =
                        <div className={'prefix-label-input ml-4'}>
                            <span>%</span><input type="number"
                                                 min={0} max={20}
                                                 value={+this.props.result.employeeContributionPercentage.toFixed(2)}
                                                 onChange={this.onEmployeeGrossChange}/>
                        </div>
                    </div>
                </div>
                </div>
                    <Slider min={0} max={20}
                            value={+this.props.result.employeeContributionPercentage.toFixed(2)}
                            onChange={this.onEmployeeGrossChange}/>

            </div>
            <div className={'selector-row'}>
                <div className={"flex items-center justify-between mb-2"}>
                    <div className={'section-heading'}>Employer's contributions</div>
                    <div className={'slider-container'}>
                        <div className={'top flex items-center'}>
                            {this.formatCurrency(this.props.result.employerContribution)} per year =
                            <div className={'prefix-label-input ml-4'}>
                                <span>%</span><input type="number"
                                                     min={0} max={20}
                                                     value={+this.props.result.employerContributionPercentage.toFixed(2)}
                                                     onChange={this.onEmployerGrossChange}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Slider min={0} max={20}
                        value={+this.props.result.employerContributionPercentage.toFixed(2)}
                        onChange={this.onEmployerGrossChange}/>
            </div>
            <div className={'selector-row'}>
                <div className={"flex items-center justify-between mb-2"}>
                    <div className={'section-heading'}>Retirement age</div>
                    <div className={'slider-container'}>
                        <div className={'top flex items-center'}>
                            <span className={"mr-4"}>years</span><input min={55} max={80}
                                   type="number"
                                   value={this.state.retirementAge}
                                   onChange={this.onRetirementAgeChange}/>
                        </div>

                    </div>
                </div>
                <Slider min={55} max={80}
                        value={this.state.retirementAge}
                        onChange={this.onRetirementAgeChange}/>
            </div>
            <div className={'selector-row'}>
                <div className={"flex items-center justify-between mb-2"}>
                    <div className={'section-heading'}>Target income in retirement</div>
                    <div className={'slider-container'}>
                        <div className={'top flex items-center'}>
                            per year<div className={'prefix-label-input ml-4'}>
                                <span>&#163;</span><input type="number"
                                                          value={this.state.yearlyTargetIncome}
                                                          min={this.state.yearlySalary * .5}
                                                          max={this.state.yearlySalary}
                                                          onChange={this.onTargetIncomeChange}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Slider min={this.state.yearlySalary * .5}
                        max={this.state.yearlySalary}
                        value={this.state.yearlyTargetIncome}
                        onChange={this.onTargetIncomeChange}/>
            </div>
            {/*<div className={'selector-row'}>
                <div className={'section-heading'}>Salary</div>
                <div className={'slider-container'}>
                    <div className={'top'}>
                        &#163;<input type="number"
                                     value={this.state.noEffectSalary}
                                     onChange={this.onSalaryChange}/> per year
                    </div>
                    <Slider min={this.props.income.oneDaySalary * 365.25 / 2}
                            max={this.props.income.oneDaySalary * 365.25 * 2}
                            value={this.state.noEffectSalary}
                            onChange={this.onSalaryChange}/>
                </div>
            </div>*/}
        </div>
    }
}

export default Results;

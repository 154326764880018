import React from "react";
import AccordionItem from "../accordion";

class Income extends React.Component {
    daysMapping = {
        yearly: 365.25,
        monthly: 30.4375,
        weekly: 7.0
    }

    salaryRangeTargetIncomeMapping = [
        {
            min: 0,
            max: 12199,
            percentage: 80
        }, {
            min: 12200,
            max: 22399,
            percentage: 70
        }, {
            min: 22400,
            max: 31999,
            percentage: 67
        }, {
            min: 32000,
            max: 51299,
            percentage: 60
        }, {
            min: 51300,
            max: null,
            percentage: 50
        },
    ]

    constructor(props) {
        super(props);
        this.state = {
            salary: props.salary,
            salaryFreq: props.salaryFreq || 'yearly',
            targetIncome: props.targetIncome || 0,
            targetFreq: props.targetFreq || 'yearly',
            oneDaySalary: props.oneDaySalary || 0,
            oneDayTargetIncome: props.oneDayTargetIncome || 0,
            targetIncomePercentage: props.targetIncomePercentage || 80
        }
    }

    onDataChange() {
        this.props.onDataChange(this.state);
    }

    calculate() {
        setTimeout(() => {
            const oneDaySalary = this.state.salary / this.daysMapping[this.state.salaryFreq];
            const yearlySalary = Math.ceil(oneDaySalary * 365.25);
            const range = this.salaryRangeTargetIncomeMapping.find(range => {
                if (yearlySalary >= range.min) {
                    if (range.max) {
                        return yearlySalary <= range.max;
                    } else {
                        return true;
                    }
                }
                return false;
            });
            const oneDayTargetIncome = oneDaySalary * (range.percentage/100);
            const targetIncome = (oneDayTargetIncome * this.daysMapping[this.state.targetFreq]).toFixed(2);

            this.setState({targetIncome, oneDaySalary, oneDayTargetIncome, targetIncomePercentage: range.percentage});
            this.onDataChange();
        })
    }

    onSalaryChange = (event) => {
        const salary = +event.target.value;
        this.setState({salary});
        this.calculate();
    }

    onSalaryFrequencyChange = (event) => {
        this.setState({
            salaryFreq: event.target.value
        });
        this.calculate();
    }

    onTargetFrequencyChange = (event) => {
        const frequency = event.target.value;
        this.setState({targetFreq: frequency});
        this.calculate();
    }

    render() {
        return <div className={'income'}>
            <div className={'section-heading'}>
                How much will you need to maintain your current lifestyle in retirement?
            </div>
            <p className={"required-message"}>* All information is required</p>

            <div className={'section-heading'}>
                Your gross salary/income *
            </div>
            <AccordionItem
                text={'This is your salary before Income Tax & National Insurance or before anything else is taken away.'}
                title={'About gross salary'}/>
            <div className={'input-block flex'}>
                <div className={'prefix-label-input'}>
                    <span>&#163;</span><input type="number" value={this.state.salary} onChange={this.onSalaryChange}/>
                </div>
                <select className={"salary-freq"} value={this.state.salaryFreq} onChange={this.onSalaryFrequencyChange}>
                    <option value="yearly">/ year</option>
                    <option value="monthly">/ month</option>
                    <option value="weekly">/ week</option>
                </select>
            </div>
            <div>
                <div className={'section-heading'}>
                    Target retirement income
                </div>
                <p>People in the same salary range as you say they would like to have around {this.state.targetIncomePercentage}% of their current salary
                    in retirement.</p>
                <p>Your target retirement income is (you can change this in Step 5 if you want to):</p>
                <div className={'input-block flex'}>
                    <div className={'prefix-label-input'}>
                        <span>&#163;</span><div className={"min-w-120 lg:min-w-200"} style={{margin: '6px 0', padding: '0 10px'}}>{this.state.targetIncome}</div>
                    </div>
                    <select className={"salary-freq"} value={this.state.targetFreq} onChange={this.onTargetFrequencyChange}>
                        <option value="yearly">/ year</option>
                        <option value="monthly">/ month</option>
                        <option value="weekly">/ week</option>
                    </select>
                </div>
                <AccordionItem
                    title={'Suggested target income for different salary ranges.'}
                    text={`
                        <p>Our suggestions are based on research into people's income needs in retirement by the Department for Work and Pensions. For example, someone who earned £20,000 a year may need a retirement income of around £14,000 a year to live off.</p>
                        <table class="lg:ml-4">
                            <thead>
                                <tr><th>Salary range (per year)</th><th>Percentage of salary needed in retirement</th></tr>
                            </thead>
                            <tbody>
                                <tr><td>Up to £12,199</td><td>80%</td></tr>
                                <tr><td>£12,200 to £22,399</td><td>70%</td></tr>
                                <tr><td>£22,400 to £31,999</td><td>67%</td></tr>
                                <tr><td>£32,000 to £51,299</td><td>60%</td></tr>
                                <tr><td>£51,300 and above</td><td>50%</td></tr>
                            </tbody>
                        </table>
                    `}/>
                <AccordionItem classname="input-block"
                               title={'How will my living costs change when I\'m retired?'}
                               text={`
                        <p>Your retirement needs will vary according to your circumstances. Many people's costs go down after they retire. For example, your outgoings should be less if:</p>
                        <ul>
                            <li>You've paid off your mortgage</li>
                            <li>You no longer pay for work travel costs</li>
                            <li>You're no longer making pension contributions</li>
                        </ul>
                    `}/>
            </div>
        </div>
    }
}

export default Income;
